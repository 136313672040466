<template>
  <div class="con">
    <!-- <h1>my.sudidi.com</h1> -->
    <h2 v-for="(it, index) in 400" :key="index">my.sudidi.com - {{ it }}</h2>
  </div>
</template>

<script>
export default {
  name: "su-sudidi-my",
  components: {},
  mounted() {
    console.log("...", this.$U);
  },
};
</script>

<style></style>
